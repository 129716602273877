import React from 'react';
import { Button } from '@admitkard/ccl/Button';
import { Text } from '@admitkard/ccl/Text';
import { theme } from '@admitkard/ccl/style';
import { AkHead } from 'components/AkHead';
import Router from 'next/router';
import { redirectUrlMapping, WITHOUT_MULTISLASH_REDIRECTION } from 'constants/redirectUrls';
import { NextPageContext } from 'next';
import { Img } from '@admitkard/ccl/Img';

interface ErrorInterface {
  statusCode: number;
  asPath?: string;
}

const cssStyles = `
  .error-page-wrapper {
    height: 100vh;
    display: flex;
    padding: ${theme.spacingOld(6)} 0;
    background-color: ${theme.palette.grey[20]};
  }
  .error-page-wrapper__content-section {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    align-items: center;
    overflow: hidden;
  }
  .error-page-wrapper__text {
    text-align: center;
    max-width: 375px;
  }
  .error-page-wrapper__action-btn {
    max-width: 50%;
    margin: ${theme.spacingOld(4)} 0 ${theme.spacingOld(4)};
  }
  :global(.error-page-wrapper__error-image) {
    margin-top: ${theme.spacingOld(7)};
    width: 100%;
  }
`;

const getWithoutMultiSlash = (currentPath: string) => {
  if (currentPath.indexOf('//') !== -1) {
    return currentPath.replace(/\/+/gm, '/');
  }
  return;
};



const getRedirectPath = (currentPath: string) => {
  const withoutMiltiSlashPath = getWithoutMultiSlash(currentPath);
  const pathToRedirect = withoutMiltiSlashPath ?
    WITHOUT_MULTISLASH_REDIRECTION :
    redirectUrlMapping.find((redirect) => {
      const redirectRegex = new RegExp(redirect.from, 'gi');
      return redirectRegex.test(currentPath || '');
    });
  if (pathToRedirect) {
    const redirectRegex = new RegExp(pathToRedirect.from, 'gi');
    return {
      from: pathToRedirect.from,
      _to: pathToRedirect.to,
      to: currentPath.replace(redirectRegex, pathToRedirect.to),
      status: pathToRedirect.status || 302,
    };
  }
  return undefined;
};

export default class Error extends React.Component<ErrorInterface> {
  static getInitialProps(ctx: NextPageContext) {
    const { res, err } = ctx;
    let statusCode = res ? res.statusCode : err ? err.statusCode : 404;

    // Redirect to legacy paths to new paths
    if (ctx.asPath && res) {
      const redirect = getRedirectPath(ctx.asPath);
      if (redirect) {
        statusCode = redirect.status;
        res.writeHead(redirect.status, {
          Location: redirect.to
        });
        res.end();
      }
    }

    return {
      statusCode,
      asPath: ctx.asPath,
    };
  }

  goToPreviousRoute = () => {
    Router.replace('/');
  };

  render() {
    let response: JSX.Element;
    switch (this.props.statusCode) {
      case 404:
        response = (
          <div className="error-page-wrapper">
            <AkHead />
            <div className="error-page-wrapper__content-section">
              <Text
                variant='xl'
                className='error-page-wrapper__text'
                display='block'
                weight='bold'>
                Uh Oh! Page not found!
              </Text>
              <Button
                color="secondary"
                className="error-page-wrapper__action-btn"
                key="lor-writers"
                size="large"
                variant='contained'
                onClick={this.goToPreviousRoute}
              >
                Go Back
              </Button>
              <div className="error-page-wrapper__error-image-container">
                <Img
                  className="error-page-wrapper__error-image"
                  alt="404 - Page Not Found" src="images/404_error.svg" />
              </div>
            </div>
            <style>{`${cssStyles}`}</style>
          </div>
        );
        break;
      case 500:
        response = (
          <div className="error-page-wrapper">
            <AkHead />
            <div className="error-page-wrapper__content-section">
              <Text
                variant='xl'
                className='error-page-wrapper__text'
                display='block'
                weight='bold'>
                Uh Oh! Something went wrong.
              </Text>
              <Button
                color="secondary"
                className="error-page-wrapper__action-btn"
                key="lor-writers"
                size="large"
                variant='contained'
                onClick={this.goToPreviousRoute}
              >
                Go Back
              </Button>
              <div className="error-page-wrapper__error-image-container">
                <Img
                  className="error-page-wrapper__error-image"
                  alt="500 - Something went wrong" src="images/500_error.svg" />
              </div>
            </div>
            <style>{`${cssStyles}`}</style>
          </div>
        );
        break;
      default:
        response = (
          <div className="error-page-wrapper">
            <AkHead />
            <div className="error-page-wrapper__content-section">
              <Text
                variant='xl'
                className='error-page-wrapper__text'
                display='block'
                weight='bold'>
                Couldn&apos;t connect with server. Please try again later.
              </Text>
              <Button
                color="secondary"
                className="error-page-wrapper__action-btn"
                key="lor-writers"
                size="large"
                variant='contained'
                onClick={this.goToPreviousRoute}
              >
                Go Back
              </Button>
              <div className="error-page-wrapper__error-image-container">
                <Img
                  className="error-page-wrapper__error-image"
                  alt="Unexpected Error occuered" src="images/general_error.svg" />
              </div>
            </div>
            <style>{`${cssStyles}`}</style>
          </div>
        );
    }
    return response;
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
type RedirectStatus = 301 | 302 | 303;
export interface RedirectUrl {
  from: string;
  to: string;
  _to?: string;
  status?: RedirectStatus; // default 302
}

const ADMISSION_PAGES_REDIRECT: Record<string, string> = { // ALL 301 redirects for admission pages
  '/statement-of-purpose': '/admissions/statement-of-purpose/overview',
  '/statement-of-purpose/overview': '/admissions/statement-of-purpose/overview',
  '/statement-of-purpose/sop-samples': '/admissions/statement-of-purpose/sop-samples',
  '/statement-of-purpose/sop-format': '/admissions/statement-of-purpose/sop-format',
  '/statement-of-purpose/sop-writing-tips': '/admissions/statement-of-purpose/sop-writing-tips',
  '/statement-of-purpose/sop-writing-services': '/admissions/statement-of-purpose/sop-writing-services',
  '/letter-of-recommendation': '/admissions/letter-of-recommendation/overview',
  '/letter-of-recommendation/overview': '/admissions/letter-of-recommendation/overview',
  '/letter-of-recommendation/lor-samples': '/admissions/letter-of-recommendation/lor-samples',
  '/letter-of-recommendation/lor-format': '/admissions/letter-of-recommendation/lor-format',
  '/letter-of-recommendation/lor-writing-tips': '/admissions/letter-of-recommendation/lor-writing-tips',
  '/admission-essays/types-of-essay': '/admissions/admission-essays/type-of-essays',
  '/admission-essays': '/admissions/admission-essays/overview',
  '/admission-essays/essay-samples': '/admissions/admission-essays/essay-samples',
  '/admission-essays/essay-guidelines': '/admissions/admission-essays/essay-guidelines',
  '/student-cv': '/admissions/student-cv/overview',
  '/student-cv/cv-samples': '/admissions/student-cv/cv-samples',
  '/student-cv/cv-format': '/admissions/student-cv/cv-format',
  '/student-cv/cv-guidelines': '/admissions/student-cv/cv-guidelines',
  '/visa/usa': '/visa/usa-student-visa/overview',
  '/visa/usa/overview': '/visa/usa-student-visa/overview',
  '/visa/usa/requirements': '/visa/usa-student-visa/requirements',
  '/visa/usa/visa-process': '/visa/usa-student-visa/visa-process',
  '/visa/usa/eligibility-&-rules': '/visa/usa-student-visa/eligibility-and-rules',
  '/visa/usa/interview': '/visa/usa-student-visa/interview',
  '/visa/australia': '/visa/australia-student-visa/overview',
  '/visa/australia/overview': '/visa/australia-student-visa/overview',
  '/visa/australia/requirements': '/visa/australia-student-visa/requirements',
  '/visa/australia/visa-process': '/visa/australia-student-visa/visa-process',
  '/visa/australia/eligibility-&-rules': '/visa/australia-student-visa/eligibility-and-rules',
  '/visa/australia/interview': '/visa/australia-student-visa/interview',
  '/visa/uk': '/visa/uk-student-visa/overview',
  '/visa/uk/overview': '/visa/uk-student-visa/overview',
  '/visa/uk/requirements': '/visa/uk-student-visa/requirements',
  '/visa/uk/visa-process': '/visa/uk-student-visa/visa-process',
  '/visa/uk/eligibility-&-rules': '/visa/uk-student-visa/eligibility-and-rules',
  '/visa/uk/interview': '/visa/uk-student-visa/interview',
  '/visa/singapore': '/visa/singapore-student-visa/overview',
  '/visa/singapore/overview': '/visa/singapore-student-visa/overview',
  '/visa/singapore/requirements': '/visa/singapore-student-visa/requirements',
  '/visa/singapore/visa-process': '/visa/singapore-student-visa/visa-process',
  '/visa/singapore/eligibility-&-rules': '/visa/singapore-student-visa/eligibility-and-rules',
  '/visa/singapore/interview': '/visa/singapore-student-visa/interview',
  '/visa/new-zealand': '/visa/new-zealand-student-visa/overview',
  '/visa/new-zealand/overview': '/visa/new-zealand-student-visa/overview',
  '/visa/new-zealand/requirements': '/visa/new-zealand-student-visa/requirements',
  '/visa/new-zealand/visa-process': '/visa/new-zealand-student-visa/visa-process',
  '/visa/new-zealand/eligibility-&-rules': '/visa/new-zealand-student-visa/eligibility-and-rules',
  '/visa/new-zealand/interview': '/visa/new-zealand-student-visa/interview',
  '/visa/canada': '/visa/canada-student-visa/overview',
  '/visa/canada/overview': '/visa/canada-student-visa/overview',
  '/visa/canada/requirements': '/visa/canada-student-visa/requirements',
  '/visa/canada/visa-process': '/visa/canada-student-visa/visa-process',
  '/visa/canada/eligibility-&-rules': '/visa/canada-student-visa/eligibility-and-rules',
  '/visa/canada/interview': '/visa/canada-student-visa/interview',
  '/crunch-prep': '/student-partner/crunchprep',
  '/student-partner/toppr': '/',
  '/hm-d': '/student/login/register?utm_source=himeesh&utm_medium=youtube&utm_campaign=hmd-QL',
  '/how-to-study-in-australia': '/student/login/register?utm_source=mt&utm_medium=youtube&utm_campaign=mt-QL',

  '/admissions/statement-of-purpose': '/admissions/statement-of-purpose/overview',
  '/admissions/letter-of-recommendation': '/admissions/letter-of-recommendation/overview',
  '/admissions/admission-essays': '/admissions/admission-essays/overview',
  '/admissions/student-cv': '/admissions/student-cv/overview',
  '/admissions/transcripts/overview': '/admissions/transcripts',

  '/visa/usa-student-visa': '/visa/usa-student-visa/overview',
  '/visa/australia-student-visa': '/visa/australia-student-visa/overview',
  '/visa/uk-student-visa': '/visa/uk-student-visa/overview',
  '/visa/singapore-student-visa': '/visa/singapore-student-visa/overview',
  '/visa/new-zealand-student-visa': '/visa/new-zealand-student-visa/overview',
  '/visa/canada-student-visa': '/visa/canada-student-visa/overview',

  '/chat/list/mentors': '/mentors',
} as const;

export const redirectUrlMapping: RedirectUrl[] = [
  {
    from: '^/university-details/.*/.*/(.*)$',
    to: '/university/$1',
    status: 301,
  },
  {
    from: '^/(.*).xml$',
    to: '/sitemap/$1.xml',
    status: 302,
  },
  ...Object.keys(ADMISSION_PAGES_REDIRECT).map((oldUrl) => ({
    from: `^${oldUrl}$`,
    to: ADMISSION_PAGES_REDIRECT[oldUrl],
    status: 301 as RedirectStatus,
  })),
];

export const WITHOUT_MULTISLASH_REDIRECTION: RedirectUrl = {
  from: '\/+', // If there are multiple // in a URL except for http://
  to: '\/',
  status: 301,
};
